
  #titles {
    font-size: 3.2rem; /* Default font size */
    font-weight: 500;
    color: white;
    /* text-align: center; */
    line-height: 1.1em;
    /* margin: 10px 0; */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    #titles {
      font-size: 2rem; /* Adjusted font size for smaller screens */
      /* line-height: 1.3; */
    }
  }
  
  /* Responsive styles for even smaller screens */
  @media (max-width: 480px) {
    #titles {
      font-size: 1.7rem; /* Further adjusted font size for very small screens */
      /* line-height: 1.2; */
    }
  }
  