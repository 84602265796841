@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");



/* Universal Sytyles */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: "Inter", sans-serif;
    background-color: #f9fafb;
    font-size: 16px;
}
.btn{
    padding: 6px 12px;
    border: 1px solid #e5e7eb;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

/* Custom Styles */

#lhsBox{
    border-right: 1px solid #e5e7eb;
    /* background-color: white; */
    position: fixed;
}
#navbarBox{
    width: 100%;
    align-items: center;
    /* background-color: white; */
    border-bottom: 1px solid #e5e7eb;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    color: white;
    height: 60px;
}
#rhsBody{
    border-radius: 8px;
    background-color: white;
    border: 1px solid #e5e7eb;
}
#titleBox{
    /* color: rgb(134, 130, 238); */
    background-color: #f3f4f6;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
#titleBox:hover{
    color: rgb(134, 130, 238); 
}
.linkItem{
    border-radius: 3px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 3px;
    font-size: 16px;
}
.linkItem:hover{
    background-color: #f3f4f6;
}
.linkActive{
    color: rgb(134, 130, 238);
    background-color: #f3f4f6;
}

table{
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
table thead tr {
    font-size: 13px;
    font-weight: 300;
    color: grey;
    height: 40px;
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
}
td,
th{
  padding: 5px;
}
table tbody tr {
    font-size: 13px;
    font-weight: 400;
    color: black;
    height: 70px;
    border-bottom: 1px solid #e5e7eb;
    border-top: 1px solid #e5e7eb;
  }
  .userRow{
    background-color: white;
  }
  .userRow:hover{
    background-color: rgb(216, 215, 247);
  }
  .productRow{
    /* font-size: 15px; */
    /* display: block; */
    color: black;
  }

  @media only screen and (min-width: 750px) and (max-width:1025px) {
    .productRow{
        /* color: red; */
        display: none;
    }
    #lhsBox{
        padding: 0px;
    }
  }
  @media only screen and (max-width:750px) {
    .productRow{
        /* color: red; */
        display: none;
    }
    .productRow2{
        display: none;
    }
    #lhsBox{
        padding: 0px;
        width: 50px;
    }
    .lhsName{
        display: none;
    }
    #rhsBody{
        margin: 5px;
    }
  }