.Laptop-view{
  height: 80px;
  width: 100%;
  position: fixed;
  /* display: inline; */
  z-index: 999;
}

.drawer-box{
  background-color: black;
  color: white;
}

.Link_btns{
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}

.Link_btns::after{
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition:3s;
  transition: width .3s;
  background: #ffffff;
}
.Link_btns:hover::after{
  width: 100%;
}
.mobileDiv{
  display: none;
}

@media all and (min-width: 624px) and (max-width: 1040px){
  .Laptop-view{
      display: none;
  }

  .mobileDiv{
      display: block;
      position: fixed;
  }

}
@media all and  (max-width: 624px){
  .Laptop-view{
      display: none;
  }

  .mobileDiv{
      display: block;
      position: fixed;
      width: 90vw;
      z-index: 999;
  }

}