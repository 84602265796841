.cursor-follow {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid blue;
  position: fixed;
  pointer-events: none; /* Ensure the circle doesn't interfere with other elements */
  transform: translate(-50%, -50%);
  transition: transform 1.9s ease-out; /* Add a smooth transition effect */
}
